import React, { useState } from 'react';
import './App.css';
import Header from './components/Header/Header';
import Content from './components/Content/Content';


const App: React.FC = () => {

  const [ measurementUnits , setMeasurementUnits] = useState("INT");
 
  return (
    <div className="App">
      <Header measurementUnits={measurementUnits} setMeasurementUnits={setMeasurementUnits} />
      <Content measurementUnits={measurementUnits} />
    </div>
  );
}

export default App;
